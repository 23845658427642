'use client';

import dynamic from 'next/dynamic';

// Dynamic imports for optimizer components
const OptimizationScript = dynamic(() => import('@/app/script').then(mod => mod.OptimizationScript), { ssr: false });
const PerformanceOptimizer = dynamic(() => import('@/components/PerformanceOptimizer').then(mod => mod.PerformanceOptimizer), { ssr: false });
const LateLoadOptimizer = dynamic(() => import('@/components/LateLoadOptimizer').then(mod => mod.LateLoadOptimizer), { ssr: false });

/**
 * Client component wrapper for performance optimizers
 * This approach fixes the issue of using dynamic imports with ssr: false in server components
 */
export function ClientOptimizers() {
  return (
    <>
      <PerformanceOptimizer />
      <OptimizationScript />
      <LateLoadOptimizer />
    </>
  );
} 