'use client';

import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import Script from 'next/script';

export function Analytics() {
  return (
    <>
      <VercelAnalytics />
      
      {/* Google Analytics - Optimized to reduce render delay */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=G-FR6X4EB6DK`}
        strategy="lazyOnload"
        id="gtm-script"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-FR6X4EB6DK', {
            send_page_view: false // We'll send this manually after the page is fully loaded
          });
          
          // Only send pageview when browser is idle or after load
          const sendPageview = () => {
            gtag('event', 'page_view');
          };
          
          if (document.readyState === 'complete') {
            if (window.requestIdleCallback) {
              requestIdleCallback(sendPageview, { timeout: 2000 });
            } else {
              setTimeout(sendPageview, 1000);
            }
          } else {
            window.addEventListener('load', () => {
              if (window.requestIdleCallback) {
                requestIdleCallback(sendPageview, { timeout: 2000 });
              } else {
                setTimeout(sendPageview, 1000);
              }
            });
          }
        `}
      </Script>
    </>
  );
} 